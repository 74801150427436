import { useLoginState } from '@/composables/store/useLoginStore';
import { UserRepository } from '@/features/user/api/userRepository';
import { useCompareVersions } from '@/composables/useCompareVersions';

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.path === '/maintenance') return;

  const repository = UserRepository();
  const { getUseWithLogIn } = useFlutterConnection();
  const {
    userId,
    authToken,
    accessKey,
    useWithLogIn,
    isTutorialViewed,
    isAgreed,
    setUserId,
    setUseWithLogIn,
    setAuthToken,
    setOldAuthToken,
  } = useLoginState();
  const { isAppSupportCognitoAuth } = useCompareVersions();
  const authSupported = isAppSupportCognitoAuth();

  if (!accessKey.value)
    throw createError(
      'アクセスキーが存在しないためアプリを起動できません。アンインストールした後、再度インストールしてください。',
    );

  if (!userId.value || !authToken.value) {
    // 新規ユーザーデータを生成
    const { data, error } = await repository.create();
    if (error.value) {
      // メンテナンスのときはエラー扱いにせずスキップ
      if (error.value.statusCode === 503) return;
      throw createError('ユーザーの情報の生成に失敗しました。');
    }

    if (authSupported) {
      await Promise.all([
        setUserId(data.value.user.id),
        setAuthToken(data.value.user.token),
        setUseWithLogIn(true), // ログイン画面を出す
      ]);
    } else {
      await Promise.all([
        setUserId(data.value.user.id),
        setOldAuthToken(data.value.user.token),
      ]);
    }
  } else {
    // 認証機能に対応しているかバージョンガード
    if (useWithLogIn.value != undefined || !authSupported) return;
    const _useWithLogIn = await getUseWithLogIn();
    // NOTE: ログイン機能導入前にすでに利用していたユーザーはそのままTOPへ遷移させる
    if (_useWithLogIn == undefined) {
      await setUseWithLogIn(!!_useWithLogIn); // ログイン画面を出さない
    }
  }

  // NOTE: ローカルで初回起動動作を確認したい場合はコメントアウトする
  const __DEBUG__ = useRuntimeConfig().public.env === 'local';
  if (__DEBUG__) return;

  // NOTE: 以下、チュートリアル/利用規約画面への遷移時は除外
  if (to.path === '/agreement' || to.path === '/tutorial') return;

  // チュートリアルを見終わっていない場合はチュートリアル画面へ遷移
  if (!isTutorialViewed.value) {
    return navigateTo('/tutorial');
  }
  // 未同意の場合は利用規約画面へ遷移
  if (!isAgreed.value) {
    return navigateTo('/agreement');
  }
});
