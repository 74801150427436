
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as about_45passnXyPQWTGDvMeta } from "/codebuild/output/src3202417174/src/src/pages/about-pass.vue?macro=true";
import { default as about_45yumenaviPKIbFGCmrEMeta } from "/codebuild/output/src3202417174/src/src/pages/about-yumenavi.vue?macro=true";
import { default as agreementR5xnODxLkOMeta } from "/codebuild/output/src3202417174/src/src/pages/agreement.vue?macro=true";
import { default as confirm_45card_45aliasyrc2dIYGRsMeta } from "/codebuild/output/src3202417174/src/src/pages/card/[id]/confirm-card-alias.vue?macro=true";
import { default as confirmAZ8NBW1RxlMeta } from "/codebuild/output/src3202417174/src/src/pages/card/[id]/confirm.vue?macro=true";
import { default as edit_45card_45aliasYpmzE9DHSlMeta } from "/codebuild/output/src3202417174/src/src/pages/card/[id]/edit-card-alias.vue?macro=true";
import { default as editfnFLGX2wKQMeta } from "/codebuild/output/src3202417174/src/src/pages/card/[id]/edit.vue?macro=true";
import { default as indextVx8eMbGmdMeta } from "/codebuild/output/src3202417174/src/src/pages/card/[id]/index.vue?macro=true";
import { default as confirmZLiS4u7peMMeta } from "/codebuild/output/src3202417174/src/src/pages/card/create/confirm.vue?macro=true";
import { default as indexZ32Lw6TwgFMeta } from "/codebuild/output/src3202417174/src/src/pages/card/create/index.vue?macro=true";
import { default as indexmkOu2S8WuhMeta } from "/codebuild/output/src3202417174/src/src/pages/card/index.vue?macro=true";
import { default as completemPGyZ8S7XKMeta } from "/codebuild/output/src3202417174/src/src/pages/contact-form/complete.vue?macro=true";
import { default as confirmn3ncjnOFiHMeta } from "/codebuild/output/src3202417174/src/src/pages/contact-form/confirm.vue?macro=true";
import { default as registerbnI2rY0Da5Meta } from "/codebuild/output/src3202417174/src/src/pages/contact-form/register.vue?macro=true";
import { default as contact3MmEZJxiPmMeta } from "/codebuild/output/src3202417174/src/src/pages/contact.vue?macro=true";
import { default as _91id_930vOSHqgMYAMeta } from "/codebuild/output/src3202417174/src/src/pages/coupon/[id].vue?macro=true";
import { default as index43RaNRFGwgMeta } from "/codebuild/output/src3202417174/src/src/pages/coupon/index.vue?macro=true";
import { default as faqZFmrhz4EHaMeta } from "/codebuild/output/src3202417174/src/src/pages/faq.vue?macro=true";
import { default as indexvtPIvi4NOFMeta } from "/codebuild/output/src3202417174/src/src/pages/history/index.vue?macro=true";
import { default as _91id_936Q670YlPs9Meta } from "/codebuild/output/src3202417174/src/src/pages/history/receipt/[id].vue?macro=true";
import { default as indexeVjENUffnoMeta } from "/codebuild/output/src3202417174/src/src/pages/index.vue?macro=true";
import { default as maintenance7qfv0fIqpiMeta } from "/codebuild/output/src3202417174/src/src/pages/maintenance.vue?macro=true";
import { default as completeTO0bTrGSjfMeta } from "/codebuild/output/src3202417174/src/src/pages/membership/complete.vue?macro=true";
import { default as confirmRyBMXLalqeMeta } from "/codebuild/output/src3202417174/src/src/pages/membership/confirm.vue?macro=true";
import { default as registerkiqceH6IUqMeta } from "/codebuild/output/src3202417174/src/src/pages/membership/register.vue?macro=true";
import { default as confirm_45codejpoX981vQhMeta } from "/codebuild/output/src3202417174/src/src/pages/membership/yumenavi/confirm-code.vue?macro=true";
import { default as confirm1qWzb5ntRLMeta } from "/codebuild/output/src3202417174/src/src/pages/membership/yumenavi/confirm.vue?macro=true";
import { default as registerLLJkxBDwj8Meta } from "/codebuild/output/src3202417174/src/src/pages/membership/yumenavi/register.vue?macro=true";
import { default as menuDX5iPIBoVgMeta } from "/codebuild/output/src3202417174/src/src/pages/menu.vue?macro=true";
import { default as indexagMi0EphlqMeta } from "/codebuild/output/src3202417174/src/src/pages/mypage/index.vue?macro=true";
import { default as indexxOnZT3oewCMeta } from "/codebuild/output/src3202417174/src/src/pages/mypage/integration/index.vue?macro=true";
import { default as sampletGLpP7Ye3FMeta } from "/codebuild/output/src3202417174/src/src/pages/mypage/integration/sample.vue?macro=true";
import { default as completegM9XwMNYbUMeta } from "/codebuild/output/src3202417174/src/src/pages/mypage/membership/email-edit/complete.vue?macro=true";
import { default as confirm_45codezGVmqiNMmTMeta } from "/codebuild/output/src3202417174/src/src/pages/mypage/membership/email-edit/confirm-code.vue?macro=true";
import { default as index1IffbfULKRMeta } from "/codebuild/output/src3202417174/src/src/pages/mypage/membership/email-edit/index.vue?macro=true";
import { default as indexx8i2fF8LHvMeta } from "/codebuild/output/src3202417174/src/src/pages/mypage/membership/index.vue?macro=true";
import { default as completeaHWHSkIHmWMeta } from "/codebuild/output/src3202417174/src/src/pages/mypage/membership/others-edit/complete.vue?macro=true";
import { default as confirm5gXMmOva1EMeta } from "/codebuild/output/src3202417174/src/src/pages/mypage/membership/others-edit/confirm.vue?macro=true";
import { default as indexpizvnXl3YqMeta } from "/codebuild/output/src3202417174/src/src/pages/mypage/membership/others-edit/index.vue?macro=true";
import { default as updatekyReVGcYwWMeta } from "/codebuild/output/src3202417174/src/src/pages/mypage/membership/others-edit/update.vue?macro=true";
import { default as confirmr8nDYAgQqIMeta } from "/codebuild/output/src3202417174/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue?macro=true";
import { default as indexHcmWppqKywMeta } from "/codebuild/output/src3202417174/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue?macro=true";
import { default as updatekwYzpsbOcLMeta } from "/codebuild/output/src3202417174/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue?macro=true";
import { default as complete5f430aeQSJMeta } from "/codebuild/output/src3202417174/src/src/pages/mypage/membership/password-edit/complete.vue?macro=true";
import { default as update0rItlC9JkAMeta } from "/codebuild/output/src3202417174/src/src/pages/mypage/membership/password-edit/update.vue?macro=true";
import { default as indexBCr680jAzOMeta } from "/codebuild/output/src3202417174/src/src/pages/mypage/pass/index.vue?macro=true";
import { default as _91id_93CJ4AfXpyQ8Meta } from "/codebuild/output/src3202417174/src/src/pages/mypage/pass/receipt/[id].vue?macro=true";
import { default as completeDUJvKCSrh8Meta } from "/codebuild/output/src3202417174/src/src/pages/mypage/sign-out/complete.vue?macro=true";
import { default as confirm2pc5iHMznTMeta } from "/codebuild/output/src3202417174/src/src/pages/mypage/sign-out/confirm.vue?macro=true";
import { default as completexcz31jJrGYMeta } from "/codebuild/output/src3202417174/src/src/pages/mypage/unsubscribe/complete.vue?macro=true";
import { default as confirmmoQ2a2lGzfMeta } from "/codebuild/output/src3202417174/src/src/pages/mypage/unsubscribe/confirm.vue?macro=true";
import { default as consentyVF2fwRd4gMeta } from "/codebuild/output/src3202417174/src/src/pages/mypage/unsubscribe/consent.vue?macro=true";
import { default as _91id_93TqyYsVzXiJMeta } from "/codebuild/output/src3202417174/src/src/pages/notice/[id].vue?macro=true";
import { default as indexI5kgDXgNZJMeta } from "/codebuild/output/src3202417174/src/src/pages/notice/index.vue?macro=true";
import { default as _91id_93GtIE2fihCmMeta } from "/codebuild/output/src3202417174/src/src/pages/parking/[id].vue?macro=true";
import { default as pass_45agreementpviIPbFpxlMeta } from "/codebuild/output/src3202417174/src/src/pages/pass-agreement.vue?macro=true";
import { default as completerOkCeJoG0aMeta } from "/codebuild/output/src3202417174/src/src/pages/reset-password/complete.vue?macro=true";
import { default as confirm_45codejopFcysA35Meta } from "/codebuild/output/src3202417174/src/src/pages/reset-password/confirm-code.vue?macro=true";
import { default as new_45password9LP7FUWFmnMeta } from "/codebuild/output/src3202417174/src/src/pages/reset-password/new-password.vue?macro=true";
import { default as send_45emailwtBxxSDjyvMeta } from "/codebuild/output/src3202417174/src/src/pages/reset-password/send-email.vue?macro=true";
import { default as confirm_45codeYxj8NdDo9vMeta } from "/codebuild/output/src3202417174/src/src/pages/sign-in/confirm-code.vue?macro=true";
import { default as indexwof68gs286Meta } from "/codebuild/output/src3202417174/src/src/pages/sign-in/index.vue?macro=true";
import { default as confirm_45code5A5A7HAIP2Meta } from "/codebuild/output/src3202417174/src/src/pages/sign-up/confirm-code.vue?macro=true";
import { default as indexcJWCe0ykngMeta } from "/codebuild/output/src3202417174/src/src/pages/sign-up/index.vue?macro=true";
import { default as tutorial1NBAr5tBrzMeta } from "/codebuild/output/src3202417174/src/src/pages/tutorial.vue?macro=true";
export default [
  {
    name: "about-pass",
    path: "/about-pass",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/about-pass.vue")
  },
  {
    name: "about-yumenavi",
    path: "/about-yumenavi",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/about-yumenavi.vue")
  },
  {
    name: "agreement",
    path: "/agreement",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/agreement.vue")
  },
  {
    name: "card-id-confirm-card-alias",
    path: "/card/:id()/confirm-card-alias",
    meta: confirm_45card_45aliasyrc2dIYGRsMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/card/[id]/confirm-card-alias.vue")
  },
  {
    name: "card-id-confirm",
    path: "/card/:id()/confirm",
    meta: confirmAZ8NBW1RxlMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/card/[id]/confirm.vue")
  },
  {
    name: "card-id-edit-card-alias",
    path: "/card/:id()/edit-card-alias",
    meta: edit_45card_45aliasYpmzE9DHSlMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/card/[id]/edit-card-alias.vue")
  },
  {
    name: "card-id-edit",
    path: "/card/:id()/edit",
    meta: editfnFLGX2wKQMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/card/[id]/edit.vue")
  },
  {
    name: "card-id",
    path: "/card/:id()",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/card/[id]/index.vue")
  },
  {
    name: "card-create-confirm",
    path: "/card/create/confirm",
    meta: confirmZLiS4u7peMMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/card/create/confirm.vue")
  },
  {
    name: "card-create",
    path: "/card/create",
    meta: indexZ32Lw6TwgFMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/card/create/index.vue")
  },
  {
    name: "card",
    path: "/card",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/card/index.vue")
  },
  {
    name: "contact-form-complete",
    path: "/contact-form/complete",
    meta: completemPGyZ8S7XKMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/contact-form/complete.vue")
  },
  {
    name: "contact-form-confirm",
    path: "/contact-form/confirm",
    meta: confirmn3ncjnOFiHMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/contact-form/confirm.vue")
  },
  {
    name: "contact-form-register",
    path: "/contact-form/register",
    meta: registerbnI2rY0Da5Meta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/contact-form/register.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/contact.vue")
  },
  {
    name: "coupon-id",
    path: "/coupon/:id()",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/coupon/[id].vue")
  },
  {
    name: "coupon",
    path: "/coupon",
    meta: index43RaNRFGwgMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/coupon/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/faq.vue")
  },
  {
    name: "history",
    path: "/history",
    meta: indexvtPIvi4NOFMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/history/index.vue")
  },
  {
    name: "history-receipt-id",
    path: "/history/receipt/:id()",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/history/receipt/[id].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexeVjENUffnoMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/maintenance.vue")
  },
  {
    name: "membership-complete",
    path: "/membership/complete",
    meta: completeTO0bTrGSjfMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/membership/complete.vue")
  },
  {
    name: "membership-confirm",
    path: "/membership/confirm",
    meta: confirmRyBMXLalqeMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/membership/confirm.vue")
  },
  {
    name: "membership-register",
    path: "/membership/register",
    meta: registerkiqceH6IUqMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/membership/register.vue")
  },
  {
    name: "membership-yumenavi-confirm-code",
    path: "/membership/yumenavi/confirm-code",
    meta: confirm_45codejpoX981vQhMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/membership/yumenavi/confirm-code.vue")
  },
  {
    name: "membership-yumenavi-confirm",
    path: "/membership/yumenavi/confirm",
    meta: confirm1qWzb5ntRLMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/membership/yumenavi/confirm.vue")
  },
  {
    name: "membership-yumenavi-register",
    path: "/membership/yumenavi/register",
    meta: registerLLJkxBDwj8Meta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/membership/yumenavi/register.vue")
  },
  {
    name: "menu",
    path: "/menu",
    meta: menuDX5iPIBoVgMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/menu.vue")
  },
  {
    name: "mypage",
    path: "/mypage",
    meta: indexagMi0EphlqMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/index.vue")
  },
  {
    name: "mypage-integration",
    path: "/mypage/integration",
    meta: indexxOnZT3oewCMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/integration/index.vue")
  },
  {
    name: "mypage-integration-sample",
    path: "/mypage/integration/sample",
    meta: sampletGLpP7Ye3FMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/integration/sample.vue")
  },
  {
    name: "mypage-membership-email-edit-complete",
    path: "/mypage/membership/email-edit/complete",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/membership/email-edit/complete.vue")
  },
  {
    name: "mypage-membership-email-edit-confirm-code",
    path: "/mypage/membership/email-edit/confirm-code",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/membership/email-edit/confirm-code.vue")
  },
  {
    name: "mypage-membership-email-edit",
    path: "/mypage/membership/email-edit",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/membership/email-edit/index.vue")
  },
  {
    name: "mypage-membership",
    path: "/mypage/membership",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/membership/index.vue")
  },
  {
    name: "mypage-membership-others-edit-complete",
    path: "/mypage/membership/others-edit/complete",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/membership/others-edit/complete.vue")
  },
  {
    name: "mypage-membership-others-edit-confirm",
    path: "/mypage/membership/others-edit/confirm",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/membership/others-edit/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit",
    path: "/mypage/membership/others-edit",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/membership/others-edit/index.vue")
  },
  {
    name: "mypage-membership-others-edit-update",
    path: "/mypage/membership/others-edit/update",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/membership/others-edit/update.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-confirm",
    path: "/mypage/membership/others-edit/yumenavi/confirm",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/membership/others-edit/yumenavi/confirm.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi",
    path: "/mypage/membership/others-edit/yumenavi",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/membership/others-edit/yumenavi/index.vue")
  },
  {
    name: "mypage-membership-others-edit-yumenavi-update",
    path: "/mypage/membership/others-edit/yumenavi/update",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/membership/others-edit/yumenavi/update.vue")
  },
  {
    name: "mypage-membership-password-edit-complete",
    path: "/mypage/membership/password-edit/complete",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/membership/password-edit/complete.vue")
  },
  {
    name: "mypage-membership-password-edit-update",
    path: "/mypage/membership/password-edit/update",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/membership/password-edit/update.vue")
  },
  {
    name: "mypage-pass",
    path: "/mypage/pass",
    meta: indexBCr680jAzOMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/pass/index.vue")
  },
  {
    name: "mypage-pass-receipt-id",
    path: "/mypage/pass/receipt/:id()",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/pass/receipt/[id].vue")
  },
  {
    name: "mypage-sign-out-complete",
    path: "/mypage/sign-out/complete",
    meta: completeDUJvKCSrh8Meta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/sign-out/complete.vue")
  },
  {
    name: "mypage-sign-out-confirm",
    path: "/mypage/sign-out/confirm",
    meta: confirm2pc5iHMznTMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/sign-out/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-complete",
    path: "/mypage/unsubscribe/complete",
    meta: completexcz31jJrGYMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/unsubscribe/complete.vue")
  },
  {
    name: "mypage-unsubscribe-confirm",
    path: "/mypage/unsubscribe/confirm",
    meta: confirmmoQ2a2lGzfMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/unsubscribe/confirm.vue")
  },
  {
    name: "mypage-unsubscribe-consent",
    path: "/mypage/unsubscribe/consent",
    meta: consentyVF2fwRd4gMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/mypage/unsubscribe/consent.vue")
  },
  {
    name: "notice-id",
    path: "/notice/:id()",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/notice/[id].vue")
  },
  {
    name: "notice",
    path: "/notice",
    meta: indexI5kgDXgNZJMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/notice/index.vue")
  },
  {
    name: "parking-id",
    path: "/parking/:id()",
    meta: _91id_93GtIE2fihCmMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/parking/[id].vue")
  },
  {
    name: "pass-agreement",
    path: "/pass-agreement",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/pass-agreement.vue")
  },
  {
    name: "reset-password-complete",
    path: "/reset-password/complete",
    meta: completerOkCeJoG0aMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/reset-password/complete.vue")
  },
  {
    name: "reset-password-confirm-code",
    path: "/reset-password/confirm-code",
    meta: confirm_45codejopFcysA35Meta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/reset-password/confirm-code.vue")
  },
  {
    name: "reset-password-new-password",
    path: "/reset-password/new-password",
    meta: new_45password9LP7FUWFmnMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/reset-password/new-password.vue")
  },
  {
    name: "reset-password-send-email",
    path: "/reset-password/send-email",
    meta: send_45emailwtBxxSDjyvMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/reset-password/send-email.vue")
  },
  {
    name: "sign-in-confirm-code",
    path: "/sign-in/confirm-code",
    meta: confirm_45codeYxj8NdDo9vMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/sign-in/confirm-code.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: indexwof68gs286Meta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/sign-in/index.vue")
  },
  {
    name: "sign-up-confirm-code",
    path: "/sign-up/confirm-code",
    meta: confirm_45code5A5A7HAIP2Meta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/sign-up/confirm-code.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: indexcJWCe0ykngMeta || {},
    component: () => import("/codebuild/output/src3202417174/src/src/pages/sign-up/index.vue")
  },
  {
    name: "tutorial",
    path: "/tutorial",
    component: () => import("/codebuild/output/src3202417174/src/src/pages/tutorial.vue")
  }
]